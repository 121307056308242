<script>
  import { mapActions } from 'vuex';
  export default {
    name: 'Logout',
    created() {
      this.logout();
    },
    methods: {
      ...mapActions('AuthModule', ['logout']),
    },
  };
</script>
