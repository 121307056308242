<template>
  <div>Loging you out...</div>
</template>

<script>
  import LogoutMixin from '@/views/auth/logout/Logout.mixin.vue';
  export default {
    mixins: [LogoutMixin],
  };
</script>
